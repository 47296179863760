import React from "react"
import Layout from "@components/layout";
import FormsParts from "@components/forms-contactform7-2";

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Recruit = () => {
  // Seo情報設定
  const pathName = 'recruit';
  const seoTag = SeoContents({ Path:pathName });

  return (
  <>
    <Layout>
      {seoTag}
      <div> 
        {/* FV */}
        <div className="corp_h1">
          <h1 className="font-semibold ">Recruit</h1>
        </div>
        <div>
          <div className="max-w-xl mx-auto  md:max-w-5xl px-6 ">
            <p className="text-[13px] text-center mt-[50px]">株式会社Enzineでは、主に金融関連のwebメディアの運営に携わってくださる方を採用しています。<br/>
            ご興味のある方からのご応募、心よりお待ちしております。</p>
            <div className="text-center mb-[50px] md:mt-[60px] mt-[60px] "> 
              <h2 className="font-semibold tracking-wide text-xl md:text-[18px]">Business content</h2>
        
            </div>
            <p className="text-[13px] mt-2 text-center">Webライターとして記事の作成やsnsの運用代行業務に取り組んでいただきます。</p>
          </div>
          <div className="text-center mb-[50px] md:mt-[60px] mt-[50px] "> 
            <h2 className="font-semibold tracking-wide text-xl md:text-[18px]">Application Requirements</h2>

          </div>
        
          <div className="bus_flex max-w-xl mx-auto  md:max-w-5xl px-6 ">
            <table className="border">
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">募集職種</td><td className="p-[20px] border-b">ライター及びsnsの運用代行</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">雇用形態</td><td className="p-[20px] border-b">業務委託契約</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">応募資格</td><td className="p-[20px] border-b">必須条件<br/>ライターとしての経験 1年以上</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">報酬</td><td className="p-[20px] border-b">要相談（取り組んでいただける業務内容で要相談）</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">勤務先</td><td className="p-[20px] border-b">リモートワーク基本</td></tr>
            </table>
          </div>

          <div className="md:max-w-2xl  mx-auto  pt-5 px-6  "> 
            <div className="text-center mb-[50px] md:mt-[50px] mt-[40px] ">
              <h2 className="font-semibold tracking-wide text-lg md:text-[18px]">Contact</h2>

            </div>
            <div className="mb-[100px]">
              <FormsParts/>
            </div>
          </div>
        </div>
      </div>
    </Layout> 
  </>
  )
}
export default Recruit