import React, {useCallback} from "react"
import axios from 'axios';
import { useForm } from 'react-hook-form';

export default function FormsParts(){
  
  // WordPress URL
  const WEBSITE_URL = 'https://hqwcebezyauv5dzpvipkc1cdh.atoz-media.co.jp';
  const { register, handleSubmit } = useForm();

  // contactフォーム7 ID
  const form_id = '435';

  // 問い合わせ内容 設定
  const count = 2; // 項目数
  const category_1 = 'select-item';
  const category_1_title = '希望の職種';
  const category_1_options_1 = 'ライター';
  const category_1_options_2 = 'その他';

  // お名前 設定
  const category_2 = 'your-name';
  const category_2_title = 'お名前';
  const category_2_pholder = '山田  太郎';
  
  // メールアドレス 設定
  const category_3 = 'your-email';
  const category_3_title = 'メールアドレス';
  const category_3_pholder = 'example@example.com';
  const category_3_annotation = '正しい形式のmailアドレスを入力してください。';

  // 電話番号 設定
  const category_4 = 'your-phone';
  const category_4_title = '電話番号';
  const category_4_pholder = '000-1234-5678';
  const category_4_annotation = '正しい形式の電話番号を入力してください。';

  // メッセージ 設定
  const category_5 = 'your-message';
  const category_5_title = 'メッセージ ※300文字以内';
  const category_5_pholder = 'メッセージを記入してください。';
  
  // その他 設定
  const required = '[ 必須 ]';
  const submit = '送信';

  const onSubmit = useCallback(data => {
    // contactform7 form data
    const bodyFormData = new FormData();
    bodyFormData.set(category_1, data[category_1]);
    bodyFormData.set(category_2, data[category_2]);
    bodyFormData.set(category_3, data[category_3]);
    bodyFormData.set(category_4, data[category_4]);
    bodyFormData.set(category_5, data[category_5]);
    
    //mailアドレス検証
    var emailElem = bodyFormData.get(category_3);
    const emailpattern =  /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    var checkEmail ;
    var checkEmailError ;
    if(!emailpattern.test(emailElem)) {
      checkEmail = document.getElementById('checkEmailId');
      checkEmail.classList.remove("hidden")
      checkEmailError = "error" ;
    }else{
      checkEmail = document.getElementById('checkEmailId');
      checkEmail.classList.add("hidden")
      checkEmailError = "clear" ;
    }

    //電話番号検証
    var telElem = bodyFormData.get(category_4);
    const telpattern =  /^(0[-0-9]{9,12}|\+[-0-9]{12,15})$/;
    var checkTel ;
    var checkTelError ;
    if(!telpattern.test(telElem)) {
      checkTel = document.getElementById('checkTelId');
      checkTel.classList.remove("hidden")
      checkTelError = "error";
    }else{
      checkTel = document.getElementById('checkTelId');
      checkTel.classList.add("hidden")
      checkTelError = "clear";
    }
    
    // submit進行
    if ( checkEmailError === "clear" && checkTelError === "clear") {
      axios({
        method: 'post',
        url:`${WEBSITE_URL}/index.php?rest_route=/contact-form-7/v1/contact-forms/${form_id}/feedback`,
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response =>{ //※成功時の遷移先URLを設定 
        window.location.href = '/thanks/'
      }).catch(error => {
        console.log('失敗');
      });
    } else if ( checkEmailError === "error" ) {
      document.getElementById("your-email").focus();
    } else if ( checkTelError === "error" ) {
      document.getElementById("your-phone").focus();
    } else {
    }
  },);

  const downArrow = <svg className="w-6 h-6 relative top-[4.1rem] right-10 text-black" fill="none" stroke ="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>

  const category1 = () =>{
    var data = () =>{
      var l = []
      for( var i = 0; i < count; i++ ){
        l.push(
        <option className="relative" value={eval('category_1_options_'+ String(i+1))}>{eval('category_1_options_'+String(i+1))}</option>)
      }
      return l
    };
    var value =
      <select
      id={category_1} {...register(category_1, {required: true})}
      className="w-full p-5 border-2 border-gray-300 mb-8 focus:outline-[#69544C]"
      >
        {data()}
      </select>
    return value
  };

return(
  <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <label htmlFor={category_1} className="w-full flex justify-between text-[#69544C] text-xs mb-2 relative">
        <h2 className="border-l-2 border-[#69544C] py-2 px-3 mb-2">{category_1_title}&nbsp;{required}</h2>
          {downArrow}
        </label>
        {category1()}
      </div>
      <div>
        <label
          htmlFor={category_2}
          className="w-full block text-[#69544C] text-xs mb-2">
          <h2 className="border-l-2 border-[#69544C] py-2 px-3 mb-2">{category_2_title}&nbsp;{required}</h2>
        </label>
        <input
          id={category_2} {...register(category_2, {required: true})} 
          placeholder={category_2_pholder}
          className="w-full p-5 border-2 border-gray-300 mb-8 focus:outline-[#69544C]"
          maxLength="40"
        />
      </div>
      <div>
        <label
          htmlFor={category_3}
          className="w-full block text-[#69544C] text-xs mb-2"
        >
        <h2 className="border-l-2 border-[#69544C] py-2 px-3 mb-2">{category_3_title}&nbsp;{required}</h2>
        </label>
        <input
          id={category_3} {...register(category_3, {required: true})}
          placeholder={category_3_pholder}
          className="w-full p-5 border-2 border-gray-300 focus:outline-[#69544C]"
          maxLength="50"
        />
        <div className="mb-3 h-5">
          <div className="text-[#69544C] text-right text-xs hidden" id="checkEmailId">{category_3_annotation}</div>
        </div>
      </div>

      <div>
        <label
          htmlFor={category_4}
          className="w-full block text-[#69544C] text-sm mb-2">
        <h2 className="border-l-2 border-[#69544C] py-2 px-3 mb-2">{category_4_title}&nbsp;{required}</h2>
        </label>
        <input
          id={category_4} {...register(category_4, {required: true})}
          placeholder={category_4_pholder}
          className="w-full p-5 border-2 border-gray-300 focus:outline-[#69544C]"
          maxLength={20}
        />
        <div className="test" style={{height: 60}}>
          <div className="text-[#69544C] text-right text-sm hidden" id="checkTelId">{category_4_annotation}</div>
        </div>
      </div>

      <div>
        <label
          htmlFor={category_5}
          className="w-full block text-[#69544C] text-sm mb-2" >
          <h2 className="border-l-2 border-[#69544C] py-2 px-3 mb-2"> {category_5_title}&nbsp;{required}</h2>
        </label>
        <textarea
          id={category_5} {...register(category_5, {required: true})}
          placeholder={category_5_pholder}
          className="w-full p-5 border-2 border-gray-300 min-h-[300px] mb-8 focus:outline-[#69544C]"
          type="text"
          maxLength="300" // 文字数制限
        />
      </div>

      <div className="w-full text-center my-10">
        <button className="rounded block my-2 px-14 py-3  text-white text-[13px] font-bold bg-[#69544C]  hover:bg-[#69544C]/80 " type="submit">{submit}</button>
      </div>
    </form>
  </>
  );
}